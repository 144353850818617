import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "reactstrap";
import { FormHeadingV2 } from "../../../../components/form-heading";
import { FormGroup, Label } from "../../../../components/form-group";
import Input, { InputErrorMessage } from "../../../../components/Input";
import { ModalFooter } from "../../../../components/modal-footer";

import { Step3Data } from "./wizard";
import { URL_REGEX } from "../resources/create-resource/create-resource-form";

type Step3FormProps = {
  onBack: () => void;
  onSubmit: (data: any) => Promise<void>;
  errors?: { message: string; extensions: string }[];
  error?: string;
  isOneStep?: boolean;
} & Step3Data;

export function Step3Form({ onSubmit, onBack, error, errors, isOneStep, ...data }: Step3FormProps) {
  const schemaObject = {
    createConfluenceArea: yup
      .string()
      .url()
      .matches(URL_REGEX, "The URL field should be valid and start with https://")
      .required("Required"),
  };

  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  const totalSteps = isOneStep ? 1 : 3;
  const currentStep = isOneStep ? 1 : 3;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-3">
      <FormHeadingV2 title={"Enter Confluence space URL"} currentStep={currentStep} totalSteps={totalSteps} />
      {errors?.length
        ? errors.map(({ message }, index) => <InputErrorMessage key={index}>{message}</InputErrorMessage>)
        : null}
      <Controller
        name="createConfluenceArea"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <div className="d-flex flex-column gap-4">
            <FormGroup label="Create a Confluence space for this team">
              <Label htmlFor="createConfluenceSpace">Confluence URL</Label>
              <Input
                type="text"
                {...field}
                placeholder="https://basproducts.atlassian.net/wiki/spaces/AS/overview"
                invalid={!!error}
              />
            </FormGroup>
          </div>
        )}
      />
      <ModalFooter
        testIdContext={`create-product-step-3`}
        backButtonEventName={`create-product-confluence-area-step-3-back`}
        nextButtonEventName={`create-product-confluence-area-step-3-submit`}
        nextButtonText="Submit"
        currentStep={currentStep}
        totalSteps={isOneStep ? 0 : totalSteps}
        isNextButtonDisabled={isSubmitting || !!error || !isValid}
        handleNextClick={onSubmit}
        handleBackClick={onBack}
        isNextButtonLoading={isSubmitting}
        backButtonText="Back"
        showBackButton={!isOneStep}
      />
    </Form>
  );
}
