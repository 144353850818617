"use client";
import { useEffect } from "react";
import { useToggle } from "../../../../hooks/useToggle";

import { Divider } from "../../../../components";
import { Button } from "../../../../components/button";
import { Link } from "../../../../components/Link";
import { Loader } from "../../../../components/spinners/loading-spinner";
import { ResourceMenu } from "./edit-delete-resource/resource-menu";
import { CreateResourceModal } from "./create-resource/create-resource-modal";

import { Add16 } from "@bphxd/ds-core-react/lib/icons";
import { KeyResourceItem } from "./key-resource-item";
import { EditConfluenceSpaceMenu } from "./edit-confluence-space-menu";
import { ProductResponse } from "../../../../gql/graphql";
import { CreateConfluenceModal } from "./create-confluence-modal";
import { EditConfluenceSpace } from "./edit-confluence-space";

export const CONFLUENCE_SPACE = "Confluence space";

export function ProductLinkedResources({
  product,
  isLoading,
  refetchProduct,
  openLinkResourceModal,
}: {
  product: ProductResponse;
  isLoading: boolean;
  refetchProduct: CallableFunction;
  openLinkResourceModal?: boolean;
  openProductAreaModal?: boolean;
}) {
  const [isCreateProductResourceModalOpen, toggleIsCreateProductResourceModalOpen] = useToggle();

  const [isEditConfluenceSpace, toggleIsEditConfluenceSpace] = useToggle();
  const [isCreateConfluenceSpace, toggleIsCreateConfluenceSpace] = useToggle();
  const canUserCreateOrEdit = product?.permissions?.edit;

  const linkedResources = product?.linkedResources ?? [];

  const confluenceSpace = linkedResources.find(({ description }) => description === CONFLUENCE_SPACE);
  const restLinkedResources = linkedResources.filter(({ description }) => description !== CONFLUENCE_SPACE);

  useEffect(() => {
    if (openLinkResourceModal) {
      toggleIsCreateProductResourceModalOpen();
    }
  }, [openLinkResourceModal]);

  if (isLoading || typeof product === "undefined") {
    return (
      <div className="rounded-5 border px-5 py-6">
        <h5 className="mb-5">Key resources</h5>
        <Loader />
      </div>
    );
  }

  return (
    <div className="bg-primary rounded-5 border px-5 py-6">
      <h5 className="mb-5">Key resources</h5>
      {confluenceSpace ? (
        <div className="d-flex justify-content-between align-items-baseline pb-3 mb-3 d-block w-100 divider-bottom">
          <div className="flex-grow-1">
            <Link
              dataTestid="product-area-link"
              href={confluenceSpace?.url ?? ""}
              name="product-area"
              trackingEventProps={{ product: product.name }}
            >
              <span className="fw-light me-2">{CONFLUENCE_SPACE}</span>
            </Link>
          </div>
          <EditConfluenceSpaceMenu toggle={toggleIsEditConfluenceSpace} productName={product.name} />
          <Divider pTop="S" pBottom="S" />
        </div>
      ) : canUserCreateOrEdit && product?.l3Entity ? (
        <>
          <div className="bg-secondary py-4 px-6 rounded-5">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <p className="mb-0 opacity-70 fw-light">Confluence integration</p>
                <p className="small fw-light opacity-70">
                  Connect a Confluence space to store all your docs in one easy to find place.
                </p>
                <div>
                  <Button
                    data-testid="create-product-area-button"
                    onClick={toggleIsCreateConfluenceSpace}
                    type="button"
                    name="create-product-area"
                    trackingEventProps={{ product: product.name }}
                    level="tertiary"
                    theme="standard"
                    rounded="pill"
                    size="sm"
                  >
                    Connect to Confluence
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Divider pTop="S" pBottom="S" />
        </>
      ) : null}

      {restLinkedResources.length === 0 && canUserCreateOrEdit ? (
        <>
          <p className="small opacity-70 fw-normal mb-3">No pinned resources</p>
        </>
      ) : (
        restLinkedResources
          .sort((a, b) => a.description.localeCompare(b.description))
          .map((item) => (
            <div
              data-testid="key-resource-item"
              key={item.id}
              className="d-flex justify-content-between align-items-baseline pb-3 mb-3 w-100 divider-bottom"
            >
              <KeyResourceItem url={item.url} description={item.description} productName={product.name} />
              {canUserCreateOrEdit && <ResourceMenu resource={item} product={product} />}
            </div>
          ))
      )}

      {canUserCreateOrEdit && (
        <Button
          name="add-linked-resource-to-product"
          data-testid="add-resource-item"
          onClick={toggleIsCreateProductResourceModalOpen}
          Icon={Add16}
          className="mt-3 mb-0"
          trackingEventProps={{ product: product.name }}
          level="tertiary"
          theme="darker"
          rounded="pill"
          size="sm"
        >
          Add a new resource
        </Button>
      )}

      <EditConfluenceSpace
        url={confluenceSpace?.url ?? ""}
        id={confluenceSpace?.id}
        toggle={toggleIsEditConfluenceSpace}
        isOpen={isEditConfluenceSpace}
      />

      <CreateConfluenceModal
        refetchProduct={refetchProduct}
        isOpen={isCreateConfluenceSpace}
        toggle={toggleIsCreateConfluenceSpace}
        url={confluenceSpace?.url ?? ""}
        productId={product.id}
      />

      <CreateResourceModal
        isModalOpen={isCreateProductResourceModalOpen}
        toggle={toggleIsCreateProductResourceModalOpen}
        selectedProduct={product}
      />
    </div>
  );
}
