import { Link } from "../../../../components/Link";

type KeyResourceItemProps = {
  productName: string;
  url: string;
  description: string;
};

export function KeyResourceItem({ productName, url, description }: KeyResourceItemProps) {
  return (
    <Link
      name="product-linked-resource"
      href={url}
      trackingEventProps={{ product: productName, resource: description }}
    >
      <span className="me-2 fw-light">{description}</span>
    </Link>
  );
}
