import { useToast } from "../../../../hooks/use-toast";
import { useMutation } from "@apollo/client";
import { CREATE_PRODUCT_LINKED_RESOURCE } from "../../../../api/products/gql";
import { Modal } from "../../../../components/modal";
import { Step3Form } from "../create/step-3";
import { CONFLUENCE_SPACE } from "./product-linked-resources";

type CreateConfluenceModalProps = {
  refetchProduct: CallableFunction;
  isOpen: boolean;
  toggle: () => void;
  url: string;
  productId: string;
};

export function CreateConfluenceModal({ refetchProduct, isOpen, toggle, url, productId }: CreateConfluenceModalProps) {
  const { displayToast } = useToast();
  const [createProductLinkedResource] = useMutation(CREATE_PRODUCT_LINKED_RESOURCE, {
    onCompleted: () => {
      displayToast("You have successfully created a Confluence space");
      toggle();
      refetchProduct();
    },
  });

  return (
    <div className="scroll-behavior-smooth">
      <Modal
        size="lg"
        backdrop="static"
        isOpen={isOpen}
        hasCancelDialog
        header="Create Confluence space"
        onClose={toggle}
      >
        <Step3Form
          isOneStep
          createConfluenceArea={url}
          onSubmit={async (data) => {
            await createProductLinkedResource({
              variables: {
                productId,
                description: CONFLUENCE_SPACE,
                url: data.createConfluenceArea,
              },
            });
          }}
          onBack={() => {}}
        />
      </Modal>
    </div>
  );
}
