import { PropsWithChildren } from "react";

import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Form, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import { Info24 } from "@bphxd/ds-core-react/lib/icons";
import { FormHeadingV2 } from "../../../../components/form-heading";
import { FormGroup } from "../../../../components/form-group";
import Input from "../../../../components/Input";
import { ModalFooter } from "../../../../components/modal-footer";
import { Step1Data } from "./wizard";
import { L3Entity } from "../../../../types/ProfileTypes";
import { Loader } from "../../../../components/spinners/loading-spinner";
import { Link } from "../../../../components/Link";

function ModalBody({ children }: PropsWithChildren) {
  return (
    <div className="d-flex flex-column">
      <p>Find a Level Three platform or service in ServiceNow to link your product to</p>
      <div>
        <Link
          href="https://accelerate.bpglobal.com/knowledgehub/EWLDys"
          name="service-now-link"
          hideExternalLinkIcon={true}
        >
          What is a ServiceNow platform or service?
        </Link>
        <span id="service-now-popover">
          <Info24 />
        </span>
        <UncontrolledPopover
          placement="right"
          target="service-now-popover"
          popperClassName="popover-dark"
          trigger="legacy"
          data-testid={`serviceNow-popover-text`}
        >
          <PopoverHeader>ServiceNow platforms and services</PopoverHeader>
          <PopoverBody>
            {`Every digital product at bp must exist as a platform or service in
            ServiceNow - the app that we use to manage them. If you are not sure
            what your product is called in ServiceNow, `}
            <Link
              name="service-now-popover-link"
              className="link-primary link-border"
              hideExternalLinkIcon={true}
              href="https://bp.service-now.com/now/nav/ui/classic/params/target/myservicehub%3Fid%3Dmsh_activity_model"
            >
              You can find it here.
            </Link>
          </PopoverBody>
        </UncontrolledPopover>
      </div>
      {children}
    </div>
  );
}

type Step1FormProps = {
  loading: boolean;
  l3Entities: L3Entity[];
  onSubmit: (data: any) => Promise<void>;
} & Step1Data;

export const NO_PRODUCTS_SELECTED = "NO_PRODUCTS_SELECTED";

export function Step1Form({ onSubmit, loading, l3Entities, ...data }: Step1FormProps) {
  const selectedProduct = data.selectedProduct;
  const allL3Entities = [
    ...l3Entities,
    {
      serviceNowId: NO_PRODUCTS_SELECTED,
      name: "The ServiceNow platform or service I want to link to is not listed here",
      tier: null,
    },
  ];
  const totalSteps = selectedProduct ? 2 : 3;

  const schemaObject = {
    l3Uid: yup.string().required("Required"),
  };

  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-1">
      <FormHeadingV2 title="Link your product to ServiceNow" currentStep={1} totalSteps={totalSteps} />
      <ModalBody>
        {loading ? (
          <Loader />
        ) : (
          <Controller
            name="l3Uid"
            control={control}
            render={({ field }) => (
              <>
                {allL3Entities.map(({ serviceNowId, name, tier }, index) => (
                  <div
                    key={serviceNowId}
                    className="d-flex align-items-center p-4 mt-3 mb-3 border border-dark-subtle border-1 rounded"
                  >
                    <FormGroup check className="mt-0">
                      <Input
                        {...field}
                        id={`product-${serviceNowId}`}
                        data-testid={`form-step-1-radio-${index}`}
                        trackingName="create-product-radio"
                        type="radio"
                        value={serviceNowId}
                        checked={serviceNowId === control._formValues.l3Uid}
                      />
                      <label htmlFor={`product-${serviceNowId}`} className="fw-medium">
                        {name}
                        {tier ? <div className="small">{`${tier?.parentName} > ${tier?.name}`}</div> : null}
                      </label>
                    </FormGroup>
                  </div>
                ))}
              </>
            )}
          />
        )}
      </ModalBody>
      <ModalFooter
        testIdContext="create-product"
        backButtonEventName="create-product"
        nextButtonEventName="create-product"
        nextButtonText="Next"
        currentStep={1}
        totalSteps={totalSteps}
        handleNextClick={onSubmit}
        isNextButtonDisabled={!isValid}
      />
    </Form>
  );
}
