import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Form, FormFeedback, FormText } from "reactstrap";
import Input from "../../../../components/Input";
import { FormGroup, Label } from "../../../../components/form-group";
import { ModalFooter } from "../../../../components/modal-footer";

import { Step2Data } from "./wizard";
import { InputErrorMessage } from "../../../../components/Input";
import { FormHeadingV2 } from "../../../../components/form-heading";

export type Step2FormProps = {
  onSubmit: (data: any) => Promise<void>;
  onBack: () => void;
  errors?: { message: string; extensions: string }[];
  yallaProductIsEditable: boolean;
} & Step2Data;

export function Step2Form({ onSubmit, onBack, errors, yallaProductIsEditable: userIsAdmin, ...data }: Step2FormProps) {
  const schemaObject = {
    name: yup
      .string()
      .trim("Cannot have leading or trailing white space.")
      .matches(/^[ a-zA-Z0-9&,\-().]*$/, "The product name must include only valid characters. Please try again.")
      .required("You must enter a product name."),
    description: yup
      .string()
      .trim("Cannot have leading or trailing white space.")
      .matches(/^[a-zA-Z0-9 \p{P}]*$/u, "Oops, you've entered an invalid character - please remove it.")
      .required("Please enter a description."),
    yallaProductName: yup
      .string()
      .trim("Cannot have leading or trailing white space.")
      .matches(/^[a-zA-Z0-9 \p{P}]*$/u, "Oops, you've entered an invalid character - please remove it.")
      .transform((value) => value || undefined),
  };
  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: data,
  });
  const selectedProduct = data.selectedProduct;
  const totalSteps = selectedProduct ? 2 : 3;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-2">
      <FormHeadingV2 title="Details" currentStep={2} totalSteps={totalSteps} />
      {errors?.length
        ? errors.map(({ message }, index) => <InputErrorMessage key={index}>{message}</InputErrorMessage>)
        : null}
      <FormGroup>
        <Label htmlFor="product_name">Name</Label>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }: any) => (
            <>
              <Input
                id="product_name"
                data-testid="product_name"
                type="text"
                placeholder="Enter a name..."
                {...field}
                invalid={!!error}
                makeRoomForError
              />
              <FormFeedback>{error?.message}</FormFeedback>
              <FormText>Your product name can only use a-z, A-Z, 0-9, &, -, (, ., ), white spaces and commas</FormText>
            </>
          )}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="description">Description</Label>
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState: { error } }: any) => (
            <>
              <Input
                id="description"
                data-testid="description"
                type="textarea"
                placeholder="Tell us about your product"
                {...field}
                invalid={!!error}
                style={{ resize: "none" }}
                maxLength={300}
                makeRoomForError
              />
              <FormFeedback>{error?.message}</FormFeedback>
              <FormText>Write a short description to help people understand the purpose of this product.</FormText>
            </>
          )}
        />
      </FormGroup>
      {userIsAdmin && (
        <FormGroup>
          <Label htmlFor="yallaProductName">Yalla product</Label>
          <Controller
            name="yallaProductName"
            control={control}
            render={({ field, fieldState: { error } }: any) => (
              <>
                <Input
                  id="yallaProductName"
                  data-testid="yallaProductName"
                  type="textarea"
                  placeholder="Enter the Yalla product name..."
                  {...field}
                  invalid={!!error}
                  style={{ resize: "none" }}
                  maxLength={300}
                  makeRoomForError
                />
                <FormFeedback>{error?.message}</FormFeedback>
                <FormText>ADMIN ONLY: Enter the exact name of this product's corresponding Yalla product..</FormText>
              </>
            )}
          />
        </FormGroup>
      )}
      <ModalFooter
        testIdContext="create-product-details"
        backButtonEventName="create-product-details"
        nextButtonEventName="create-product-details-step-2-submit"
        backButtonText="Back"
        showBackButton
        nextButtonText={selectedProduct ? "Submit" : "Next"}
        currentStep={2}
        totalSteps={totalSteps}
        isNextButtonLoading={isSubmitting}
        isNextButtonDisabled={!isValid}
        handleBackClick={onBack}
      />
    </Form>
  );
}
