"use client";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";

import { UPDATE_PRODUCT_LINKED_RESOURCE } from "../../../../../api/products/gql";
import { useToast } from "../../../../../hooks/use-toast";

import { Form } from "reactstrap";
import { FormHeading } from "../../../../../components/form-heading";
import { FormGroup, Label } from "../../../../../components/form-group";
import Input from "../../../../../components/Input";
import { ModalFooter } from "../../../../../components/modal-footer";

import { Product, LinkedResource } from "../../../../../types/ProfileTypes";
import { URL_REGEX } from "../create-resource/create-resource-form";

type WizardProps = {
  onClose: () => void;
  resource: LinkedResource;
  selectedProduct: Product;
};

type StepData = {
  description: string;
  url: string;
};

export function EditResourceForm({ onClose, resource }: WizardProps) {
  const { displayToast } = useToast();
  const [stepData, setStepData] = useState<StepData>({
    description: resource?.description,
    url: resource?.url,
  });

  const schemaObject = {
    description: yup
      .string()
      .strict()
      .trim("The resource name cannot have leading or trailing white spaces.")
      .matches(/^[ a-zA-Z0-9_-]*$/, "Valid characters include a-z, A-Z, 0-9, _, -, and white spaces.")
      .required("Required"),
    url: yup
      .string()
      .url()
      .matches(URL_REGEX, "The URL field should be valid and start with https://")
      .required("Required"),
  };

  const schema = yup.object(schemaObject).required();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: stepData,
  });

  const [updateProductLinkedResource] = useMutation(UPDATE_PRODUCT_LINKED_RESOURCE, {
    onCompleted: () => {
      displayToast(`You have successfully edited a key resource link`);
      onClose();
    },
  });

  async function onSubmit(data: StepData) {
    setStepData(data);
    await updateProductLinkedResource({
      variables: {
        id: resource?.id,
        description: data.description,
        url: data.url,
      },
    });
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="edit-resource-form">
      <FormHeading title="" />
      <FormGroup>
        <Label htmlFor="resource-name">Resource name</Label>
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState: { error } }: any) => (
            <>
              <Input
                id="resource-name"
                data-testid="resource-name"
                type="text"
                placeholder="For example: Product 2x2, Roadmap, Operating plan"
                {...field}
                error={error?.message}
                invalid={!!error}
              />
            </>
          )}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="url">URL</Label>
        <Controller
          name="url"
          control={control}
          defaultValue={"test name"}
          render={({ field, fieldState: { error } }: any) => (
            <>
              <Input
                id="url"
                data-testid="resource-url"
                type="text"
                placeholder="For example: https://basproducts.atlassian.net/wiki/spaces/AS/overview"
                {...field}
                error={error?.message}
                invalid={!!error}
                makeRoomForError
              />
            </>
          )}
        />
      </FormGroup>
      <ModalFooter
        testIdContext="add-product-resource-form-button"
        nextButtonEventName={"Edit"}
        nextButtonText={"Edit"}
        handleNextClick={onSubmit}
        isNextButtonLoading={isSubmitting}
        isNextButtonDisabled={!isValid}
      />
    </Form>
  );
}
