import { DropdownKebabMenu } from "../../../../components/kebab-menu";
import { DropdownItem } from "reactstrap";
import { trackEvent } from "../../../../utils/event-tracker";
import { Edit24 } from "@bphxd/ds-core-react/lib/icons/index";

export function EditConfluenceSpaceMenu({ productName, toggle }: { productName: string; toggle: () => void }) {
  return (
    <DropdownKebabMenu
      trackingEventProps={{
        name: "button_confluence_menu_click",
        productName,
      }}
      size="sm"
    >
      <DropdownItem
        onClick={() => {
          toggle();
          trackEvent({
            name: "button_key-resource-menu-edit",
            productName,
          });
        }}
      >
        <Edit24 className="me-2" />
        Edit Confluence space
      </DropdownItem>
    </DropdownKebabMenu>
  );
}
