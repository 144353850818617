"use client";
import { useState } from "react";
import { useMutation } from "@apollo/client";

import { useToast } from "../../../../hooks/use-toast";
import {
  CREATE_ACCELERATE_PRODUCT,
  CREATE_PRODUCT_LINKED_RESOURCE,
  EDIT_ACCELERATE_PRODUCT,
  GET_ACCELERATE_PRODUCTS,
} from "../../../../api/products/gql";

import { NO_PRODUCTS_SELECTED, Step1Form } from "./step-1";
import { Step2Form } from "./step-2";
import { Step3Form } from "./step-3";
import { NoSNOWEntity } from "./no-snow-entity-selected";

import { L3Entity } from "../../../../types/ProfileTypes";
import { BasicProductFieldsFragment, ProductResponse } from "../../../../gql/graphql";
import { useLocation } from "../../../../hooks/use-location";
import { trackEvent } from "../../../../utils/event-tracker";
import { CONFLUENCE_SPACE } from "../resources/product-linked-resources";

type WizardProps = {
  loading: boolean;
  onClose: () => void;
  selectedProduct?: ProductResponse | BasicProductFieldsFragment;
  l3Entities: L3Entity[];
  yallaProductIsEditable: boolean;
};

export type Step1Data = {
  selectedProduct?: ProductResponse | BasicProductFieldsFragment;
  l3Uid: string;
};

export type Step2Data = {
  selectedProduct?: ProductResponse | BasicProductFieldsFragment;
  name: string;
  description: string;
  yallaProductName?: string;
};

export type Step3Data = {
  createConfluenceArea: string;
};

const NO_SNOW_MESSAGE_STEP = -1;

export function Wizard({ onClose, loading, l3Entities, selectedProduct, yallaProductIsEditable }: WizardProps) {
  const [currentStep, setCurrentStep] = useState(1);
  const { router } = useLocation();

  const [step1Data, setStep1Data] = useState<Step1Data>({
    selectedProduct,
    l3Uid: selectedProduct?.l3EntityUid ?? "",
  });
  const [step2Data, setStep2Data] = useState<Step2Data>({
    selectedProduct,
    name: selectedProduct?.name || "",
    description: selectedProduct?.description || "",
    yallaProductName: selectedProduct?.yallaProductName || undefined,
  });
  const [step3Data, setStep3Data] = useState<Step3Data>({
    createConfluenceArea: "",
  });
  const { displayToast, displayError } = useToast();

  const [createProductLinkedResource] = useMutation(CREATE_PRODUCT_LINKED_RESOURCE);
  const [createAccelerateProduct, { error: createError }] = useMutation(CREATE_ACCELERATE_PRODUCT, {
    refetchQueries: [{ query: GET_ACCELERATE_PRODUCTS }],
    onCompleted: async ({ createProduct }) => {
      trackEvent({
        name: "product_created",
        productName: createProduct?.name,
        productDescription: createProduct?.description,
        l3Entity: createProduct?.l3Entity?.name,
      });
    },
    onError: () => {
      displayError("Unable to create the product");
    },
  });
  const [editAccelerateProduct, { error: editError }] = useMutation(EDIT_ACCELERATE_PRODUCT, {
    onCompleted: () => {
      displayToast(`Successfully edited ${step2Data?.name}`);
      onClose();
    },
    onError: () => {
      displayError("Unable to edit the product");
      onClose();
    },
  });
  // This is a common bug when reaching for Apollo errors
  const errors = createError
    ? // @ts-ignore
      createError.networkError?.result?.errors
    : editError
      ? editError.graphQLErrors
      : null;

  function goToPreviousStep() {
    setCurrentStep((currentStep) => currentStep - 1);
  }

  async function handleStep1Submit(data: Step1Data) {
    setStep1Data(data);
    // Allow Yalla product to be edited outside of L3 linkage flow
    if (data?.l3Uid === NO_PRODUCTS_SELECTED && !yallaProductIsEditable) {
      setCurrentStep(NO_SNOW_MESSAGE_STEP);
    } else {
      setCurrentStep(2);
    }
  }
  async function handleStep2Submit(data: Step2Data) {
    setStep2Data(data);
    if (selectedProduct) {
      await editAccelerateProduct({
        variables: {
          id: selectedProduct.id,
          ...step1Data,
          ...data,
        },
      });
      onClose();
      displayToast(`Successfully edited ${selectedProduct?.name}`);
      router?.push(`/profile/product/${selectedProduct.id}`);
    } else {
      setCurrentStep(3);
    }
  }
  async function handleStep3Submit(data: Step3Data) {
    setStep3Data(data);

    // create product
    const results = await createAccelerateProduct({
      variables: {
        ...step1Data,
        ...step2Data,
      },
    });
    const resultingProduct = results?.data?.createProduct as ProductResponse;
    await createProductLinkedResource({
      variables: {
        productId: resultingProduct.id,
        description: CONFLUENCE_SPACE,
        url: data?.createConfluenceArea ?? "",
      },
    });
    onClose();
    displayToast(`Successfully created ${step2Data?.name}`);
    router?.push(`/profile/product/${resultingProduct.id}`);
  }

  return (
    <>
      <div style={{ display: currentStep === 1 ? "block" : "none" }}>
        <Step1Form {...step1Data} loading={loading} l3Entities={l3Entities} onSubmit={handleStep1Submit} />
      </div>
      <div style={{ display: currentStep === 2 ? "block" : "none" }}>
        <Step2Form
          {...step2Data}
          onBack={goToPreviousStep}
          onSubmit={handleStep2Submit}
          errors={errors}
          yallaProductIsEditable={yallaProductIsEditable}
        />
      </div>
      <div style={{ display: currentStep === 3 ? "block" : "none" }}>
        <Step3Form {...step3Data} onBack={goToPreviousStep} onSubmit={handleStep3Submit} errors={errors} />
      </div>
      <div
        style={{
          display: currentStep === NO_SNOW_MESSAGE_STEP ? "block" : "none",
        }}
      >
        <NoSNOWEntity onClose={onClose} />
      </div>
    </>
  );
}
